// import styles
import "./MyProjectsItems.css";

// import components
import React from 'react';
import { useNavigate } from "react-router-dom";

function MyProjectsItems({ projects }) {

  // Create navigate object
  const navigate = useNavigate();

  // Pass down specific project data (depending on id) to project/:id to display that one project
  const handleNavigateWithProjectData = (projectId) => {
    navigate(`/project/${projectId}`, { state: { projects: projects } });
  };

  return (
    <div className="MyProjectsItems">
      <div className="MyProjectsHeaderContainer">
        <p className="MyProjectsTitle">My Projects</p>
      </div>
      <div className="ItemsListContainer">
        <div className="ProjectTitles">
          {projects.map((project, index) => (
            <div className="ProjectItem" onClick={() => handleNavigateWithProjectData(project.id)}>
              <img className="ProjectItemImage" src={project.image} alt={project.title}/>
              <p key={index} className="MyProjectsTitleFont" style={{ color: "white", fontSize: "32px", marginLeft: "100px" }}>{project.title}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default MyProjectsItems;
