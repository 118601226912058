// import styles
import "./AboutMePage.css";

// import components
import GlobalNavBar from "../../Components/GlobalNavBar/GlobalNavBar";
import { useLocation } from 'react-router-dom';
import AboutMeImage from "../../Media/BruceTwo.png";
import { useEffect } from "react";
import BottomPageInfo from "../../Components/BottomPageInfo/BottomPageInfo";

function AboutMePage() {
    const location = useLocation();
    const projects = location.state.projects;

    useEffect(() => {
      // Start off from the top of the page
      window.scrollTo(0, 0);
    }, []);

    return(
        <div className="AboutMePage">
            <GlobalNavBar projectsData={projects} fadeIn={false}/>
            <div
        style={{
          backgroundColor: "transparent",
          width: "100%",
          height: "82px",
        }}
      ></div>
      <div className="AboutContainer">
        <div className="AboutPictureContainer">
          <img
            className="AboutPicture"
            src={AboutMeImage}
            alt="About Me Profile"
          />
        </div>
        <div className="AboutMeContainer">
          <div className="AboutMeHeaderContainer">
            <p className="AboutMeHeader AboutMeText TitleFont">About Me</p>
          </div>
          <div className="AboutMeBioContainer">
            <p className="AboutMeBio AboutMeText">
              My name is Bruce Evaristo. I dream of working inside a team of
              talented engineers and disciplines as a sound designer. Born and
              raised in California, I’ve always enjoyed and appreciated the
              music and movies that were presented to me growing up. This
              enjoyment soon manifested into a desire of one day working under
              the audio industry. From my very first experience in working with
              audio in high school on Logic Pro & FL, to then graduate from
              University of Silicon Valley (previously known as Cogswell
              Polytechnical College). All of my work gravitates primarily around
              creating sonic imaging, editing, (post) mixing, and recording
              stunning audio for all to see in front of their own screens; such
              as the one you are looking at to read this. I’ve enjoyed working
              with teams of different disciplines such as my experience in
              MediaWorks: a class that gave students professional projects from
              clients.
            </p>
          </div>
        </div>
      </div>
      <BottomPageInfo projectsData={projects} />
        </div>
    )
}

export default AboutMePage;