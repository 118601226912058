// import styles
import "./IDProjectPage.css";

// import components
import { useParams, useLocation } from "react-router-dom";
import GlobalNavBar from "../../Components/GlobalNavBar/GlobalNavBar";
import BottomPageInfo from "../../Components/BottomPageInfo/BottomPageInfo";
import { useEffect } from "react";

function IDProjectPage() {
  const { id } = useParams();
  const location = useLocation();
  const { projects } = location.state;

  const currentProject = projects.find(
    (project) => project.id === parseInt(id)
  );

  useEffect(() => {
    // Start off from the top of the page
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="IDProjectPage">
      <GlobalNavBar projectsData={projects} fadeIn={false}/>
      <div
        style={{
          backgroundColor: "transparent",
          width: "100%",
          height: "250px",
        }}
      ></div>
      <div className="ProjectDisplayContainer">
        <div className="IDProjectVideoContainer">
            <video width="691.2" height="461" controls poster={currentProject.image}>
            <source src={currentProject.video} type="video/mp4"></source>
            <source src={currentProject.video} type="video/mov"></source>
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="IDProjectDetailsContainer">
          <h2 className="IDProjectText IDProjectTitle">{currentProject.fullTitle}</h2>
          <p className="IDProjectText">{currentProject.description}</p>
          <p className="IDProjectText">Start Date: {currentProject.startDate}</p>
          <p className="IDProjectText">End Date: {currentProject.endDate}</p>
          <div className="ClientNameAndPic">
            <div className="ClientNameContainer">
              <a target="_blank" href={`${currentProject.clientLink}`} className="ClientName" rel="noreferrer">{currentProject.clientName}</a>
            </div>
            <img src={currentProject.clientImage} alt="ClientPicture" className="ClientPic"/>
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "transparent",
          width: "100%",
          height: "430px",
        }}
      ></div>
      <BottomPageInfo />
    </div>
  );
}

export default IDProjectPage;
