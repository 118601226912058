// import styles
import "./TestimonialsItems.css";

// import components
import React from 'react';

function TestimonialsItems(props) {

    const testimonialsData = props.testimonialsData;

    return (
        <div className="TestimonialsItems">
          <p className="TestimonialsTitle">Testimonials</p>
          <div className="TestimonialsContainer">
            {testimonialsData.map((testimonial, index) => (
              <div key={index} className="TestimonialItemContianer">
                <img src={testimonial.portrait} className="TestimonialPortrait" alt="TestimonialPortrait"/>
                <div className="WholeTestimonial">
                    <i className="TestimonialText">"{testimonial.testimonial}"</i>
                    <p className="TestimonialText">{testimonial.name}</p>
                    <p className="TestimonialText">{testimonial.profession}</p>
                    <p className="TestimonialText">{testimonial.company}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      );
}

export default TestimonialsItems;
