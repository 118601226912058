// import styles
import "./BottomPageInfo.css";

// import media
import BottomPageImage from "../../Media/BruceThree.png";
import { FaLinkedin } from "react-icons/fa";
import { FaSquareInstagram } from "react-icons/fa6";

function BottomPageInfo() {
  //Set up the function to scroll back to the top
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  return (
    <div className="BottomPageInfoContainer">
      <div className="BottomPageInfo">
        <div className="BottomPagePicture">
          <img className="BottomPagePictureImage" src={BottomPageImage} alt="Bruce Profile" />
        </div>
        <div className="BottomPageContactInfo">
          <p className="BottomInfoText">Bruce Evaristo - Sound Designer</p>
          {/* <p className="BottomInfoText"></p>
          <p className="BottomInfoText"></p> */}
          <button
            className="BottomToTopButton BottomInfoText"
            onClick={scrollToTop}
          >
            Back To Top
          </button>
          <div className="BottomPageSocialMediaContainer">
            <a href="https://www.linkedin.com/in/bruce-evaristo-591aa6259" target="_blank" rel="noreferrer">
              <FaLinkedin color={"black"} size={40} />
            </a>
            <a href="https://www.instagram.com/bruceaevaristo/" target="_blank" rel="noreferrer">
              <FaSquareInstagram color={"black"} size={40} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BottomPageInfo;
