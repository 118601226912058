// import styles
import "./GlobalNavBar.css";

// import components
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

function GlobalNavBar(props) {
  const projects = props.projectsData;
  const navigate = useNavigate();
  const [isTransparent, setIsTransparent] = useState(true);
  const fadeIn = props.fadeIn;

  // If fadeIn is true, then make the NavBar transition
  useEffect(() => {
    if (fadeIn) {
      const handleScroll = () => {
        const position = window.pageYOffset;

        if (position >= 115) {
          setIsTransparent(false);
        } else {
          setIsTransparent(true);
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    } else {
      setIsTransparent(false);
    }
  }, [fadeIn]);

  // Navigations
  const handleNavigateToProjects = () => {
    navigate("/myprojects", { state: { projects: projects } });
  };
  const handleNavigateToAboutMe = () => {
    navigate("/aboutme", { state: { projects: projects } });
  };
  const handleNavigateToTestimonials = () => {
    navigate("/testimonials", { state: { projects: projects } });
  };
  const handleNavigateToContact = () => {
    navigate("/contact", { state: { projects: projects } });
  };

  return (
    <div
      className={`GlobalNavBar ${isTransparent ? "transparent" : ""}`}
      style={{
        backgroundColor: isTransparent ? "transparent" : "#fff1e8",
        color: isTransparent ? "black" : "inherit",
        transition: fadeIn
          ? "background-color 0.5s ease, color 0.5s ease"
          : "none",
      }}
    >
      <div className="NavBarEntry">
        <div className="NavBarInfoContainer">
          <button
            className={`NavBarText NavButton NavBarTitleFont ${
              isTransparent ? "NavBarTitleFontInvis" : "NavBarTitleFont"
            } ${fadeIn ? "" : "FadeInTransitionFalse"}`}
            onClick={handleNavigateToProjects}
            style={{
              transition: fadeIn ? "color 0.5s ease, transform 0.5s ease" : "transform 0.5s ease",
            }}
          >
            My Projects
          </button>
        </div>
      </div>
      <div className="NavBarEntry">
        <div className="NavBarInfoContainer">
          <button
            className={`NavBarText NavButton NavBarTitleFont ${
              isTransparent ? "NavBarTitleFontInvis" : "NavBarTitleFont"
            } ${fadeIn ? "" : "FadeInTransitionFalse"}`}
            onClick={handleNavigateToAboutMe}
            style={{
              transition: fadeIn ? "color 0.5s ease, transform 0.5s ease" : "transform 0.5s ease",
            }}
          >
            About Me
          </button>
        </div>
      </div>
      <div className="NavBarEntry">
        <div className="NavBarInfoContainer">
          <button
            className={`NavBarText Name NavBarTitleFont ${
              isTransparent ? "NavBarTitleFontInvis" : "NavBarTitleFont"
            } ${fadeIn ? "" : "FadeInTransitionFalse"}`}
            onClick={() => {
              navigate("/");
            }}
            style={{
              transition: fadeIn ? "color 0.5s ease, transform 0.5s ease" : "transform 0.5s ease",
            }}
          >
            Bruce Evaristo
          </button>
        </div>
        <div className="NavBarInfoContainer">
          <p
            className={`NavBarText NavBarSkill ${
              isTransparent ? "NavBarTextInvis" : "NavBarText"
            } ${fadeIn ? "" : "FadeInTransitionFalse"}`}
            style={{
              transition: fadeIn ? "color 0.5s ease, transform 0.5s ease" : "transform 0.5s ease",
            }}
          >
            Sound Designer
          </p>
        </div>
      </div>
      <div className="NavBarEntry">
        <div className="NavBarInfoContainer">
          <button
            className={`NavBarText NavButton NavBarTitleFont ${
              isTransparent ? "NavBarTitleFontInvis" : "NavBarTitleFont"
            } ${fadeIn ? "" : "FadeInTransitionFalse"}`}
            onClick={handleNavigateToTestimonials}
            style={{
              transition: fadeIn ? "color 0.5s ease, transform 0.5s ease" : "transform 0.5s ease",
            }}
          >
            Testimonials
          </button>
        </div>
      </div>
      <div className="NavBarEntry">
        <div className="NavBarInfoContainer">
          <button
            className={`NavBarText NavButton NavBarTitleFont ${
              isTransparent ? "NavBarTitleFontInvis" : "NavBarTitleFont"
            } ${fadeIn ? "" : "FadeInTransitionFalse"}`}
            onClick={handleNavigateToContact}
            style={{
              transition: fadeIn ? "color 0.5s ease, transform 0.5s ease" : "transform 0.5s ease",
            }}
          >
            Contact Me
          </button>
        </div>
      </div>
    </div>
  );
}

export default GlobalNavBar;
