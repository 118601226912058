// import styles
import "./TestimonialsPage.css";

// import components
import GlobalNavBar from "../../Components/GlobalNavBar/GlobalNavBar";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import BottomPageInfo from "../../Components/BottomPageInfo/BottomPageInfo";
import TestimonialsItems from "../../Components/TestimonialsItems/TestimonialsItems";

// import portrait images
import DeszoMolnarPortrait from "../../Media/Portraits/DeszoMolnarPortrait.png";
import AESLogo from "../../Media/Portraits/AESLogo.png";

function TestimonialsPage() {
  const location = useLocation();
  const projects = location.state.projects;

  useEffect(() => {
    // Start off from the top of the page
    window.scrollTo(0, 0);
  }, []);

  const [testimonials] = useState([
    {
      // name profession company portrait and testimonial
      name: "Dezso Molnar",
      profession: "Inventor of the Gyrocycle and Streetwing",
      // company: "Noneee babyyyy",
      portrait: DeszoMolnarPortrait,
      testimonial: "As lucky collaborators with Bruce and everyone on the USV MediaWorks team, it has been a professional joy to be both hands off and looped in during their ambitious efforts to position our new flying car design. Commuting? No! Racing, camping, ice crossings and a custom soundtrack that pulls it all together the way The Dude's rug binds his apartment?? YES!! They nailed the important items, let the little things go, and created fun surprises all after being provided with only a parametric drawing and leading concise conversations. MediaWorks turned out a magical animation that creates plausible narratives few people would imagine without seeing them. Bravo!! MediaWorks USV is a talented operation and their efforts will be center stage as we share the dream with the same enthusiasm they showed supporting the Molnar team.",
    },
    {
      // name profession company portrait and testimonial
      name: "The 2023 Committee of AES San Francisco",
      // profession: "",
      company: "Audio Engineering Society San Francisco",
      portrait: AESLogo,
      testimonial: 'The committee of AES San Francisco was very pleased with the work that Bruce and the MediaWorks team created. We received three options to choose from and it was really tough because they were all excellent. The production is solid. The spot tells the story clearly and encompasses a variety of disciplines within audio that we had mentioned. It has a great build up and gave us “the audience is listening" feel and really frames the video/story. Great work. It commands the attention and seems more unique than what people usually hear before their video starts - we’ll be using this piece for our online events, streams and permanent videos as our official intro.',
    },
  ]);

  return (
    <div className="TestimonialsPage">
      <GlobalNavBar projectsData={projects} fadeIn={false} />
      <div
        style={{
          backgroundColor: "transparent",
          width: "100%",
          height: "200px",
        }}
      />
      <TestimonialsItems testimonialsData={testimonials} />
      <div
        style={{
          backgroundColor: "transparent",
          width: "100%",
          height: "100px",
        }}
      />
      <BottomPageInfo projectsData={projects} />
    </div>
  );
}

export default TestimonialsPage;
