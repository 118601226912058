// import styles
import "./HomePage.css";

// import components
import GlobalNavBar from "../../Components/GlobalNavBar/GlobalNavBar";
import { useState, useEffect } from "react";
import BottomPageInfo from "../../Components/BottomPageInfo/BottomPageInfo";

// import thumbnail images
import SFAESImage from "../../Media/Thumbnail/SFAES.png";
import MWDiasporaImage from "../../Media/Thumbnail/MWDiaspora.png";
import KaizYTImage from "../../Media/Thumbnail/KaizYT.png";
import StreetwingImage from "../../Media/Thumbnail/StreetwingThumbnail.png";
import EchoesOfAnyaImage from "../../Media/Thumbnail/EchoesOfAnyaThumbnail.png"

// import HomePage image
import HomePortrait from "../../Media/Bruce_Potrait.jpg";

// import portrait images
import AESLogo from "../../Media/Portraits/AESLogo.png";
import KaizPortrait from "../../Media/Portraits/KaizPortrait.png";
import BineetSharmaPortrait from "../../Media/Portraits/BineetSharmaPortrait.png";
import DeszoMolnarPortrait from "../../Media/Portraits/DeszoMolnarPortrait.png";
import JadenGilroyPortrait from "../../Media/Portraits/JadenGilroyPortrait.png"


// import videos
import SFAESVideo from "../../Media/Videos/MW_AES_PostMixingFINAL.mov";
import MWDiasporaVideo from "../../Media/Videos/MWDiaspora_BD_PostVer1.mp4";
import KaizYTVideo from "../../Media/Videos/Kaiz_SonicImaging_Mix1.1.mov";
import StreetwingVideo from "../../Media/Videos/Streetwing_Post_Mix.mp4";
import EchoesOfAnyaVideo from "../../Media/Videos/Level3_Showcase.mov";

function HomePage() {

  useEffect(() => {
    // Start off from the top of the page
    window.scrollTo(0, 0);
  }, []);

  // Set up projects through here
  const [projects] = useState([
    {
      id: 1,
      title: "MediaWorks: AES Project",
      fullTitle: 'Project #1: "MediaWorks: Audio Engineering Society Logo Branding Reveal"',
      startDate: "07/19/2023",
      endDate: "08/18/2023",
      description:
        "My first project where I was a sound designer for a client. This was an important project for me, as I represent the organization as a member of Audio Engineering Society (AES). I was one of three sound designers in a team of six audio engineers. As a sound designer, I took charge in capturing my own sounds that were in need. Some examples are a crowd cheering in a concert, ambience of a live sound venue, a portrayal of how audio restoration works, and many other sounds. I have also learned how to edit and mix my sound design using ProTools & Reaper. Upon finishing all my tasks, I volunteered to help with other sounds that were missing from other sound designers. Lastly, I presented the AES project in front of professors and students in my university, where I talked about the process and experience working in a team of 2D, 3D, and Audio disciplines.",
      image: SFAESImage,
      video: SFAESVideo,
      clientName: "Audio Engineering Society",
      clientLink: "https://aes2.org",
      clientImage: AESLogo,
    },
    {
      id: 2,
      title: "MediaWorks: Molnar's Streetwing",
      fullTitle: "Project #2: MediaWorks: Molnar's Streetwing",
      startDate: "09/15/2023",
      endDate: "10/18/2023",
      description:
        "The last project of three projects that I have worked on under the MediaWorks Class. Working as a team of two, I worked with a classmate of mine that worked on the composition, and I did the sound designer. I began with obtaining the seven various ambiences by traveling and doing location recording. I was also in charge of getting concrete sounds that fit the appropriate environments. Once I’ve finished editing, mixing, and spotting the sounds, I then begin the difficult sounds: the streetwing skating on ice, & the quiet engines. After experimenting with the sounds relentlessly, I’ve managed to create and add the sounds onto the project. As soon as my partner and I finish with our deliverables, we’ve created a post-mix of our work & then sent it to the client; along with a spot interview",
      image: StreetwingImage,
      video: StreetwingVideo,
      clientName: "Deszö Molnár",
      clientLink: "https://www.dezsomolnar.com",
      clientImage: DeszoMolnarPortrait,
    },
    {
      id: 3,
      title: "Echoes of Anya",
      fullTitle: 'Project #3: Echoes of Anya',
      startDate: "02/05/2024",
      endDate: "04/17/2024",
      description:
        "My very first interactive project. This was an ongoing project that I volunteered to be a part of in its last months until we turned it in to the client. During my brief time in this project, I worked as the only sound designer in the audio team of three. As I introduced myself to the team, I started dedicating most of my time on this project during my last semester in my university.  I’ve also volunteered to do some audio implementation in Unreal Engine for the team, as we also lacked implementors. My work consisted of turning in audio that I have worked on every week. Some examples of the audio I have created are ambience for a daunting cellar, clothing foley for the main character, and many more.",
      image: EchoesOfAnyaImage,
      video: EchoesOfAnyaVideo,
      clientName: "Jaden Gilroy",
      clientLink: "https://jadengilroy.carbonmade.com/",
      clientImage: JadenGilroyPortrait,
    },
    {
      id: 4,
      title: "Kaiz Youtube Intro",
      fullTitle: 'Project #4: "Kaiz Ch. | カイズ: Sonic Imaging"',
      startDate: "01/22/2024",
      endDate: "02/10/2024",
      description:
        "With the help of some references sent by the client, I was able to create sonic imaging for a YouTube channel intro. I first started sending him some samples and prototypes based on what he asked, until he caught interest in the texture of one. All sounds that are present in the intro were created using VCV Rack and mixed on ProTools. My client really wanted that sharp and technical feel that one would hear after taking a look at his animation. So I then talked with him regarding different textures that could make it sound more full. I added a low subwoofer layer as well as some slices to make it sound dynamic.",
      image: KaizYTImage,
      video: KaizYTVideo,
      clientName: "Kaiz Ch. | カイズ",
      clientLink: "https://www.youtube.com/@KaizCh",
      clientImage: KaizPortrait,
    },
    {
      id: 5,
      title: "MediaWorks: Diaspora Intro",
      fullTitle: 'Project #5: "MediaWorks: Diaspora Story Podcast Intro Composition"',
      startDate: "09/15/2023",
      endDate: "10/18/2023",
      description:
        "During my time working on this project, I've volunteered to work as a composer inside a team of Audio, 2D & 3D disciplines. In the team of eight audio engineers, we were split into teams of two: one composer and one sound designer. This was a fairly difficult project to work on due to it having an ethnic goal and approach. Nevertheless, this project has given me the experience of adapting to projects that I have little connection with. In the composition, I’ve used instruments that complement traditional Nepal music theory. I’ve learned about Nepal's composition and its instruments such as the bansuri & sarangi. It took me eight hours to finally figure out a melody that felt memorable, and attractive for all that watched the introduction. After finishing the composition, I started working on the post mix; which I turned in on time. As a result of our work, the client chose ours as the mix to go with for his podcast.",
      image: MWDiasporaImage,
      video: MWDiasporaVideo,
      clientName: "Bineet Sharma",
      clientLink: "https://www.linkedin.com/in/sharmabineet",
      clientImage: BineetSharmaPortrait,
    },
  ]);

  return (
    <div className="HomePage">
      <GlobalNavBar projectsData={projects} fadeIn={true}/>
      <div className="PortraitContainer">
        <img className="PortraitPicture" src={HomePortrait} alt="Bruce Portrait" />
        <div className="PortraitGradient"></div>
        <div className="PortraitGradientTwo"></div>
      </div>
      <div
        style={{
          backgroundColor: "transparent",
          width: "100%",
          height: "675px",
        }}
      ></div>
      <BottomPageInfo projectsData={projects} />
    </div>
  );
}

export default HomePage;
