// import styles
import "./ContactForm.css";

// import components
import emailjs from '@emailjs/browser'
import { useState } from "react";

function ContactForm() {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
  };
  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  function handleSubmit(e) {
    if (name === "") {
      e.preventDefault();
      alert("Hold up, you forgot to type your name");
    } else if (email === "") {
      e.preventDefault();
      alert("Hold up, you forgot to type your email address");
    } else if (message === "") {
      e.preventDefault();
      alert("Hold up, you forgot to type your message");
    } else {

      e.preventDefault();
      
      // Objects for containing data from EmailJS
      const serviceId = "service_ym2j6yg";
      const templateId = "template_xf2klha";
      const publicKey = "3BhpaysolPzGRIc21";

      // Create object containing DYNAMIC template parameters
      const templateParams = {
        from_name: name,
        from_email: email,
        subject: subject,
        to_name: 'Bruce Evaristo',
        message: message,
      }

      // Send the email using EmailJS
      emailjs.send(serviceId, templateId, templateParams, publicKey)
        .then((response) => {
            console.log('Email sent successfully!', response);
            alert("Thank you! I will get back to you as soon as possible!");
            e.target.reset();
        })
        .catch((error) => {
            console.log('Error sending email', error);
            alert("Error");
        })
    }
  }

    return (
    <form className="ContactForm" onSubmit={handleSubmit}>
      <div className="FormTitleContainer">
        <p className="FormTitle">Contact</p>
      </div>
      <div className="MainFormContainer">
        <div className="FormNameAndEmailContainer">
          <div className="InputContainer">
            <p className="FormLabelText" style={{ margin: "0px 0px 5px 0px" }}>
              Name
            </p>
            <input
              className="NameAndEmailInputClass"
              placeholder="Your Name"
              name="name"
              onChange={handleNameChange}
              required
            />
          </div>
          <div className="InputContainer">
            <p className="FormLabelText" style={{ margin: "0px 0px 5px 0px" }}>
              Email
            </p>
            <input
              className="NameAndEmailInputClass"
              placeholder="Email Address"
              name="email"
              onChange={handleEmailChange}
              required
            />
          </div>
        </div>
        <div className="MailContainer">
          <div>
            <p className="FormLabelText" style={{ margin: "0px 0px 5px 0px" }}>
              Subject
            </p>
            <input
              className="SubjectInput"
              placeholder="Subject"
              name="subject"
              onChange={handleSubjectChange}
              required
            />
          </div>
          <div>
            <p className="FormLabelText" style={{ margin: "0px 0px 5px 0px" }}>
              Message
            </p>
            <textarea
              className="MessageInput"
              placeholder="Enter Message Here"
              name="message"
              onChange={handleMessageChange}
              required
            ></textarea>
          </div>
        </div>
      </div>
      <button className="SendButton" type="submit">
        Send
      </button>
    </form>
  );
}

export default ContactForm;
