// import styles
import './MyProjectsPage.css';

// import components
import React from 'react';
import GlobalNavBar from '../../Components/GlobalNavBar/GlobalNavBar';
import { useLocation } from 'react-router-dom';
import MyProjectsItems from '../../Components/MyProjectsItems/MyProjectsItems';
import BottomPageInfo from '../../Components/BottomPageInfo/BottomPageInfo';
import { useEffect } from 'react';

function MyProjectsPage() {
  const location = useLocation();
  const projects = location.state.projects;

  useEffect(() => {
    // Start off from the top of the page
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="ProjectsListPage">
      <GlobalNavBar projectsData={projects} fadeIn={false}/>
      <div
        style={{
          backgroundColor: "transparent",
          width: "100%",
          height: "250px",
        }}
      ></div>
      <div className="ProjectsListItems">
        <MyProjectsItems projects={projects} />
      </div>
      <div
        style={{
          backgroundColor: "transparent",
          width: "100%",
          height: "225px",
        }}
      ></div>
      <BottomPageInfo />
    </div>
  );
}

export default MyProjectsPage;
