// App.js
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

// import pages
import HomePage from './Pages/HomePage/HomePage';
import ContactPage from './Pages/ContactPage/ContactPage';
import MyProjectsPage from './Pages/MyProjectsPage/MyProjectsPage';
import IDProjectPage from './Pages/IDProjectPage/IDProjectPage';
import AboutMePage from './Pages/AboutMePage/AboutMePage';
import TestimonialsPage from './Pages/TestimonialsPage/TestimonialsPage';

const App = () => {
  return (
    <BrowserRouter> {/* Wrap your routes with BrowserRouter */}
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/contact" element={<ContactPage/>} />
        <Route path="/myprojects" element={<MyProjectsPage/>} />
        <Route path="/project/:id" element={<IDProjectPage/>} />
        <Route path="/aboutme" element={<AboutMePage/>} />
        <Route path="/testimonials" element={<TestimonialsPage/>} />
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;